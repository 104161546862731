<template>
  <div class="register-header">
    <r-title>{{ title }}</r-title>
    <mail-notification
      module="register"
      :object-ids="objectIds"
    />
    <map-toggler
      :map-visible="mapVisible"
      @toggle="$emit('toggle')"
    />
    <export-button
      :uri="exportUrl"
      name="Реестр закрытых участков дорог"
    />
  </div>
</template>

<script>
import mapToggler from '../map-toggler/map-toggler'
import exportButton from '@/components/export-button'
import mailNotification from '@/components/mail-notification/mail-notification'
import { exportConfig } from '../../config/'
import { makeExportUrl } from '@/utils'

export default {
  components: {
    mapToggler,
    exportButton,
    mailNotification
  },
  props: {
    title: {
      type: String,
      required: true
    },
    mapVisible: {
      type: Boolean,
      required: true
    },
    uri: {
      type: String,
      required: true
    },
    objectIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      exportUrl: ''
    }
  },
  watch: {
    uri() {
      this.initUrl()
    }
  },
  mounted() {
    this.initUrl()
  },
  methods: {
    initUrl() {
      this.exportUrl = makeExportUrl(this.uri, exportConfig)
    }
  }
}
</script>

<style lang="scss">
.register-header {
  flex: 1;
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  align-items: center;
  padding: 0.5rem 1rem;
  grid-template-columns: 1fr auto auto;
  background-color: var(--bg_panel_primary);
  border-bottom: 1px solid var(--dividers_low_contrast);
}
</style>
