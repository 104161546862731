import vue from 'vue'

const setSourceIdById = (hash, array) => {
  array.forEach(({ id, source_id, children }) => {
    hash[id] = source_id

    if (children && children.length) setSourceIdById(hash, children)
  })
}

const state = {
  profileConstructor: {
    profileId: '',
    profile: {},
    checkedData: [],
    checkedDataProfile: [],
    clearChecked: {
      dsTree: false,
      profile: false
    }
  },
  currentProfile: {},
  currentProfileTree: [],
  sourceIdById: {},
  updatedTreeTS: null,

  profilesTree: [],
  profileUpdated: false,
  treeExpandedNodes: [],
  configCache: {}
}

const mutations = {
  PROFILES_SET_FIELD(state, [field, value]) {
    state[field] = value
  },
  SET_CURRENT_PROFILE(state, profile) {
    vue.set(state, 'currentProfile', profile)
    vue.set(state.profileConstructor, 'profile', profile)
  },
  CHANGE_CONSTRUCTOR_PROFILE(state, profile) {
    vue.set(state.profileConstructor, 'profile', profile)
  },
  SET_PROFILES_TREE(state, profilesTree) {
    vue.set(state, 'profilesTree', profilesTree)
  },
  UPDATE_CHECKED_DATA(state, { checkedData, isProfile = false }) {
    if (!isProfile) {
      vue.set(state.profileConstructor, 'checkedData', checkedData)
    } else {
      vue.set(state.profileConstructor, 'checkedDataProfile', checkedData)
    }
  },
  CLEAR_CHECKED_DATA(state, isProfile = false) {
    if (!isProfile) {
      vue.set(state.profileConstructor, 'checkedData', [])
      vue.set(state.profileConstructor.clearChecked, 'dsTree', true)
    } else {
      vue.set(state.profileConstructor, 'checkedDataProfile', [])
      vue.set(state.profileConstructor.clearChecked, 'profile', true)
    }
  },
  CHANGE_PROFILE(state, action) {
    vue.set(state, 'profileUpdated', action)
  },
  UPDATE_PROFILES_TREE(state, profile) {
    state.profilesTree.map(e => {
      if (e.id === profile.id) {
        e.locale = 'ru'
        e.theme = profile.theme
      }
    })
  },
  SET_TREE_EXPANDED_NODES(state, nodes) {
    vue.set(state, 'treeExpandedNodes', nodes)
  },
  SET_CONSTRUCTOR_PROFILE_ID(state, id) {
    vue.set(state.profileConstructor, 'profileId', id)
  },
  SET_CURRENT_PROFILE_TREE(state, treeData) {
    vue.set(state, 'currentProfileTree', treeData)

    setSourceIdById(state.sourceIdById, treeData)
  },
  SET_CONFIG_CACHE(state, { module, id, data }) {
    if (module in state.configCache) {
      state.configCache[module][id] = data
    } else {
      state.configCache[module] = { [id]: data }
    }
  }
}

export default {
  mutations,
  state
}
