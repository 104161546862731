import { rDate, ritmDate } from '@/utils'

const initFrom = rDate.calc(-12, 'hour', ritmDate.date()).format('iso')

export const filters = {
  car_event: [
    {
      id: 'event_time',
      source: null,
      type: 'interval-filter',
      title: 'Дата события',
      pickerType: 'datetime',
      format: 'dd.MM.yyyy HH:mm',
      prop: { interval: { from: initFrom, to: '' } },
      noClearable: true,
      active: true
    },
    {
      id: 'event_type',
      source: null,
      type: 'checkbox-filter',
      title: 'Тип события',
      prop: [
        { id: 'cross_geozone_in', name: 'Вход в геозону' },
        { id: 'cross_geozone_out', name: 'Выход из геозоны' },
        { id: 'deviation_from_route', name: 'Отклонение от маршрута' },
        { id: 'return_to_route', name: 'Выход на маршрут' },
        { id: 'over_speed', name: 'Превышение допустимой скорости' },
        { id: 'geozone_over_speed', name: 'Превышение скорости в геозоне' },
        { id: 'move', name: 'Движение' },
        { id: 'stop', name: 'Остановка' },
        { id: 'status_changed', name: 'Потеря связи с объектом' }
      ],
      active: false
    },
    {
      id: 'vehicle.model_id',
      source: 'model',
      type: 'checkbox-filter',
      title: 'Модель',
      prop: []
    },
    {
      id: 'vehicle.vehicle_type_id',
      source: 'vehicle_type',
      type: 'checkbox-filter',
      title: 'Вид ТС',
      prop: []
    },
    {
      id: 'vehicle.vehicle_type.vehicle_type_group.id',
      source: 'vehicle_type_group',
      type: 'checkbox-filter',
      title: 'Тип ТС',
      prop: []
    },
    {
      id: 'contractor_id_tmc',
      source: 'contractor_tmc',
      type: 'checkbox-filter',
      title: 'Подрядчики ТМЦ',
      prop: []
    },
    {
      id: 'contractor_id_not_tmc',
      source: 'contractor_not_tmc',
      type: 'checkbox-filter',
      title: 'Подрядчики внутренние',
      prop: []
    },
    {
      id: 'vehicle.sub_contractor_id',
      source: 'sub_contractor',
      type: 'checkbox-filter',
      title: 'Субподрядчик',
      prop: []
    }
  ],
  system_event: [
    {
      id: 'end_time',
      source: null,
      type: 'interval-filter',
      title: 'Дата события',
      pickerType: 'datetime',
      format: 'dd.MM.yyyy HH:mm',
      prop: { interval: { from: initFrom, to: '' } },
      noClearable: true,
      active: true
    }
  ],
  gazprom: [
    {
      id: 'vehicle.vehicle_type.vehicle_type_group.id',
      source: 'vehicle_type_group',
      type: 'checkbox-filter',
      title: 'Тип ТС',
      prop: []
    },
    {
      id: 'contractor_id_tmc',
      source: 'contractor_tmc',
      type: 'checkbox-filter',
      title: 'Подрядчики ТМЦ',
      prop: []
    },
    {
      id: 'contractor_id_not_tmc',
      source: 'contractor_not_tmc',
      type: 'checkbox-filter',
      title: 'Подрядчики внутренние',
      prop: []
    },
    {
      id: 'vehicle.sub_contractor_id',
      source: 'sub_contractor',
      type: 'checkbox-filter',
      title: 'Субподрядчик',
      prop: []
    },
    {
      id: 'action_type',
      source: null,
      type: 'checkbox-filter',
      title: 'Тип события',
      prop: [
        { id: 1, name: 'Въезд ТС на территорию' },
        { id: 2, name: 'Выезд ТС с территории' }
      ],
      active: false
    },
    {
      id: 'no_kpp',
      type: 'string-filter',
      title: '№ КПП',
      prop: '',
      active: false
    },
    {
      id: 'time',
      source: null,
      type: 'interval-filter',
      title: 'Дата события',
      pickerType: 'datetime',
      format: 'dd.MM.yyyy HH:mm',
      prop: { interval: { from: initFrom, to: '' } },
      noClearable: true,
      active: true
    }
  ],
  videophoto: [
    {
      id: 'vehicle.model_id',
      source: 'model',
      type: 'checkbox-filter',
      title: 'Модель',
      prop: []
    },
    {
      id: 'vehicle.vehicle_type.vehicle_type_group.id',
      source: 'vehicle_type_group',
      type: 'checkbox-filter',
      title: 'Тип ТС',
      prop: []
    },
    {
      id: 'contractor_id_tmc',
      source: 'contractor_tmc',
      type: 'checkbox-filter',
      title: 'Подрядчики ТМЦ',
      prop: []
    },
    {
      id: 'contractor_id_not_tmc',
      source: 'contractor_not_tmc',
      type: 'checkbox-filter',
      title: 'Подрядчики внутренние',
      prop: []
    },
    {
      id: 'vehicle.sub_contractor_id',
      source: 'sub_contractor',
      type: 'checkbox-filter',
      title: 'Субподрядчик',
      prop: []
    },
    {
      id: 'vehicle.applications.permits.number',
      type: 'string-filter',
      title: '№ пропуска',
      prop: '',
      active: false
    },
    {
      id: 'action_type',
      source: null,
      type: 'checkbox-filter',
      title: 'Тип события',
      prop: [
        { id: 9, name: 'Проезд ТС ФВФ' },
        { id: 10, name: 'Превышение скорости по ФВФ' },
        { id: 11, name: 'ФВФ: Выезд на встречную полосу' }
      ],
      active: false
    },
    {
      id: 'serial',
      source: null,
      type: 'checkbox-filter',
      title: 'Камера',
      prop: [
        { id: 1316, name: 'ПАД 4 - ПАД 11' },
        { id: 1372, name: '1й км а/д ПАД 1' },
        { id: 1348, name: '5й км а/д ПАД 1' },
        { id: 1349, name: '3й км а/д ПАД 1' },
        { id: 1345, name: '1й км а/д ПАД 2' }
      ],
      active: false
    },
    {
      id: 'time',
      source: null,
      type: 'interval-filter',
      title: 'Дата события',
      pickerType: 'datetime',
      format: 'dd.MM.yyyy HH:mm',
      prop: { interval: { from: initFrom, to: '' } },
      noClearable: true,
      active: true
    },
    {
      id: 'over_speed',
      type: 'range-filter',
      title: 'Превышение',
      prop: { interval: { from: '', to: '' } },
      active: false
    },
    {
      id: 'direction_id',
      source: null,
      type: 'checkbox-filter',
      title: 'Направление',
      prop: [
        { id: 1, name: 'Встречное' },
        { id: 2, name: 'Попутное' },
        { id: 0, name: 'Не определено' }
      ],
      active: false
    }
  ],
  videoanalytics: [
    {
      id: 'action_type',
      source: null,
      type: 'checkbox-filter',
      title: 'Тип события',
      prop: [
        { id: 45, name: 'Фиксация ДТП' },
        { id: 44, name: 'Фиксация затора' }
      ],
      active: false
    },
    {
      id: 'vehicle.vehicle_type_id',
      source: 'vehicle_type',
      type: 'checkbox-filter',
      title: 'Вид ТС',
      prop: []
    },
    {
      id: 'vehicle.vehicle_type.vehicle_type_group.id',
      source: 'vehicle_type_group',
      type: 'checkbox-filter',
      title: 'Тип ТС',
      prop: []
    },
    {
      id: 'contractor_id_tmc',
      source: 'contractor_tmc',
      type: 'checkbox-filter',
      title: 'Подрядчики ТМЦ',
      prop: []
    },
    {
      id: 'contractor_id_not_tmc',
      source: 'contractor_not_tmc',
      type: 'checkbox-filter',
      title: 'Подрядчики внутренние',
      prop: []
    },
    {
      id: 'vehicle.sub_contractor_id',
      source: 'sub_contractor',
      type: 'checkbox-filter',
      title: 'Субподрядчик',
      prop: []
    },
    {
      id: 'time',
      source: null,
      type: 'interval-filter',
      title: 'Дата события',
      pickerType: 'datetime',
      format: 'dd.MM.yyyy HH:mm',
      prop: { interval: { from: initFrom, to: '' } },
      noClearable: true,
      active: true
    }
  ],
  nipigaz: [
    {
      id: 'vehicle.vehicle_type.vehicle_type_group.id',
      source: 'vehicle_type_group',
      type: 'checkbox-filter',
      title: 'Тип ТС',
      prop: []
    },
    {
      id: 'contractor_id_tmc',
      source: 'contractor_tmc',
      type: 'checkbox-filter',
      title: 'Подрядчики ТМЦ',
      prop: []
    },
    {
      id: 'contractor_id_not_tmc',
      source: 'contractor_not_tmc',
      type: 'checkbox-filter',
      title: 'Подрядчики внутренние',
      prop: []
    },
    {
      id: 'vehicle.sub_contractor_id',
      source: 'sub_contractor',
      type: 'checkbox-filter',
      title: 'Субподрядчик',
      prop: []
    },
    {
      id: 'camera_name',
      type: 'string-filter',
      title: 'Камера',
      prop: '',
      active: false
    },
    {
      id: 'time',
      source: null,
      type: 'interval-filter',
      title: 'Дата события',
      pickerType: 'datetime',
      format: 'dd.MM.yyyy HH:mm',
      prop: { interval: { from: initFrom, to: '' } },
      noClearable: true,
      active: true
    }
  ],
  nipigaz_pot: [
    {
      id: 'vehicle.vehicle_type.vehicle_type_group.id',
      source: 'vehicle_type_group',
      type: 'checkbox-filter',
      title: 'Тип ТС',
      prop: []
    },
    {
      id: 'contractor_id_tmc',
      source: 'contractor_tmc',
      type: 'checkbox-filter',
      title: 'Подрядчики ТМЦ',
      prop: []
    },
    {
      id: 'contractor_id_not_tmc',
      source: 'contractor_not_tmc',
      type: 'checkbox-filter',
      title: 'Подрядчики внутренние',
      prop: []
    },
    {
      id: 'vehicle.sub_contractor_id',
      source: 'sub_contractor',
      type: 'checkbox-filter',
      title: 'Субподрядчик',
      prop: []
    },
    {
      id: 'action_type',
      source: null,
      type: 'checkbox-filter',
      title: 'Камера',
      prop: [
        { id: 4, name: 'Въезд ПОТ' },
        { id: 5, name: 'Выезд ПОТ' }
      ],
      active: false
    },
    {
      id: 'time',
      source: null,
      type: 'interval-filter',
      title: 'Дата события',
      pickerType: 'datetime',
      format: 'dd.MM.yyyy HH:mm',
      prop: { interval: { from: initFrom, to: '' } },
      noClearable: true,
      active: true
    }
  ]
}
