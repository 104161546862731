<template>
  <div
    v-loading="loading"
    class="restrictions-register"
  >
    <register-header
      :title="$t('restrictions-register:title')"
      :map-visible="isMapVisible"
      :uri="uri"
      :object-ids="objectIds"
      @toggle="toggleMap"
    />
    <r-filter-set
      class="restrictions-register__filters"
      :filters="filters"
      @reset-all="resetFilters"
      @load="updateFilters"
    />
    <div class="restrictions-register__content">
      <register-map
        v-if="isMapVisible && halfHeigth"
        :height="halfHeigth"
      />
      <r-table-list
        v-if="events.length"
        ref="table"
        :fields-names="fields"
        :include-columns="columns"
        bordered
        :data="tableData"
        actions
        num-col
        :paginator="events.length > 10"
        selectable
        @click-handler="openModal"
      >
        <template v-slot:actions="{ row }">
          <div class="restrictions-register-table__action-cell">
            <button
              class="restrictions-register-table__action-button"
              @click.stop="showOnMap(row)"
            >
              <r-icon
                name="focus-zone"
                :size="20"
              />
              <r-text> На карте </r-text>
            </button>
          </div>
        </template>
      </r-table-list>
    </div>
  </div>
</template>

<script>
import { filters } from './config/filters'
import { buildUrl, buildExportUri, filtersEncoder } from './helpers'
import registerHeader from './components/header/header'
import registerMap from './components/map/map'
import { getDatasourcesByDatatype, getModelChildrenIds } from '@/utils'

export default {
  components: { registerHeader, registerMap },
  data() {
    return {
      loading: false,
      isMapVisible: false,
      filters: [],
      fields: {
        no: '№',
        created_at: 'Создано',
        type: 'Тип',
        name: 'Название',
        address: 'Адрес',
        relevance: 'Актуальность',
        closed_line_count: 'Перекрытие',
        start_time: 'Начало',
        requested: 'Заявка',
        end_time: 'Окончание'
      },
      columns: [
        'no',
        'created_at',
        'type',
        'name',
        'address',
        'start_time',
        'end_time',
        'closed_line_count',
        'requested',
        'relevance'
      ],
      events: [],
      uri: `objectInfo/${this.source_id}`,
      eventClasses: null,
      source_id: ''
    }
  },
  computed: {
    halfHeigth() {
      return Math.round(document.documentElement.clientHeight / 2 - 80)
    },
    tableData() {
      return this.events.map(e => ({
        ...e,
        created_at: this.$ritmDate.toFormat(e.created_at, 'DD.MM.YYYY • HH:mm'),
        start_time: this.$ritmDate.toFormat(e.start_time, 'DD.MM.YYYY • HH:mm'),
        end_time: this.$ritmDate.toFormat(e.end_time, 'DD.MM.YYYY • HH:mm'),
        name: e.name || '—',
        address: e.address || '—',
        type: e.event_class?.name,
        requested: e.requested ? 'Да' : 'Нет',
        closed_line_count: e.closed_line_count ? 'Есть' : 'Нет'
      }))
    },
    objectIds() {
      return this.tableData?.map(e => e.id) || []
    },
    updateRestrictions() {
      return this.$store.state.bdd.updateRestrictions
    }
  },
  watch: {
    updateRestrictions() {
      this.loadEvents()
    }
  },
  async mounted() {
    this.loading = true
    this.setFilters()
    await this.loadEventsIds()
    this.loadEvents()
    this.loading = false
  },
  methods: {
    setFilters() {
      const userFilters = this.$store.state.bdd.filters?.restrictions
      const initFilters = filters()

      this.filters = initFilters?.map(f => {
        if (!userFilters?.length) return f

        const userItem = userFilters?.find(uf => uf.id === f.id)

        return userItem || f
      })
    },
    async loadEventsIds() {
      try {
        const models = await getDatasourcesByDatatype(this, 'model')
        const model = models.find(m => !!m.is_main_model) || models[0]

        const { event_classes, events } = getModelChildrenIds(model.children)

        this.source_id = events
        await this.loadEventClasses(event_classes)
      } catch (e) {
        console.warn(e)
      }
    },
    async loadEvents() {
      try {
        const filters = filtersEncoder(this.filters)
        const url = buildUrl(this.source_id, filters)
        this.uri = buildExportUri(this.source_id, filters)

        const eventsData = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        const data = Object.values(eventsData.data)

        this.events = data.map(e => ({
          ...e,
          relevance: this.getRelevance(e.start_time, e.end_time),
          type: e.event_class?.name
        }))
      } catch (e) {
        console.warn(e)
      } finally {
        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateRestrictions',
          value: false
        })
      }
    },
    async loadEventClasses(source_id) {
      try {
        const eventClasses = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${source_id}`
        })
        this.eventClasses = Object.values(eventClasses.data)

        const eventFilter = this.filters.find(f => f.id === 'event_class_id')

        eventFilter.prop = this.eventClasses.map(e => {
          const fValue = !!eventFilter.prop?.find(fi => fi.id === e.id)?.value

          return {
            value: fValue,
            name: e.name,
            id: e.id
          }
        })
      } catch (e) {
        console.warn(e)
      }
    },
    toggleMap() {
      this.isMapVisible = !this.isMapVisible
    },
    async resetFilters() {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'filters.restrictions',
        value: this.filters?.filter(f => f.active)
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }

      await this.loadEvents()
    },
    async updateFilters(filter) {
      const target = this.filters.find(f => f.id === filter.id)

      const item = { ...target, ...filter }

      this.filters[this.filters.indexOf(target)] = item

      this.$store.commit('BDD_SET_FIELD', {
        field: 'filters.restrictions',
        value: this.filters?.filter(f => f.active)
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }

      this.loadEvents()
    },
    openModal(row) {
      const { source_id, eventClasses } = this
      const value = {
        ...this.events.find(e => e.id === row.id),
        source_id,
        eventClasses
      }
      this.$store.commit('BDD_SET_FIELD', {
        field: 'restriction',
        value
      })
      this.$store.commit('OPEN_MODAL_WINDOW', 'bdd-restriction-modal')
    },
    showOnMap(row) {
      this.$refs.table.highlightRow(row)

      this.$emit('setCurrentRow', 1)
      this.isMapVisible = true

      this.$store.commit('BDD_SET_FIELD', {
        field: 'restriction',
        value: row
      })

      this.$store.commit('BDD_SET_FIELD', {
        field: 'rrflyToGeom',
        value: row.geom
      })
    },
    getRelevance(start, end) {
      if (start && this.$ritmDate.date(start) > this.$ritmDate.date()) return 'Предстоящее'
      if (!start || !end) return '—'
      return this.$ritmDate.date(end) < this.$ritmDate.date() ? 'Завершено' : 'В процессе'
    }
  }
}
</script>

<style lang="scss">
.restrictions-register {
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  width: 100%;
  align-content: start;
  height: 100%;

  &__filters {
    border-bottom: 1px solid var(--dividers_low_contrast);
  }

  &-table {
    &__action {
      &-cell {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1rem;
        justify-content: start;
      }

      &-button {
        border-radius: var(--border-radius);
        border: none;
        background-color: transparent;
        display: grid;
        grid-gap: 0.25rem;
        grid-auto-flow: column;
        cursor: pointer;

        &:hover {
          background-color: var(--accent_hover);
        }
      }
    }
  }

  &__content {
    display: grid;
    grid-template-rows: 1fr;
    overflow: hidden;
    height: 100%;
    align-items: center;
    justify-content: stretch;

    .r-title {
      justify-content: center;
      width: 100%;
      padding: 1rem;
      text-align: center;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "restrictions-register:title": "Реестр закрытых участков дорог",
    "restrictions-register:tooltip-map": "Показать на карте",
    "restrictions-register:tooltip-edit": "Подробная информация и редактирование"
  },
  "en": {
    "restrictions-register:title": "Register of closed road sections",
    "restrictions-register:tooltip-map": "Show on the map",
    "restrictions-register:tooltip-edit": "Details and editing"
  }
}
</i18n>
