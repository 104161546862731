export const getConfig = (ids, fields = []) => {
  const config = {
    where: { field: 'id', op: Array.isArray(ids) ? 'in' : '=', value: ids }
  }

  config.only = fields?.filter(f => !f.reflection)?.map(f => f.origin_title || f.title)

  const reflections = fields?.filter(f => f.reflection)

  reflections.forEach(r => {
    if (!config.include) config.include = {}

    const through = r.reflection.through

    config.include[r.title] = { only: ['id', r.reflection?.default_show_attribute].filter(e => e) }

    if (through) config.include[through] = {}
  })

  if (!config.only.find(e => e === 'id')) {
    config.only.push('id')
  }

  return config
}

export const getChangedData = (id, fields, changed) => {
  const item = { id }

  Object.keys(changed).forEach(cf => {
    const field = fields.find(f => f.source_name === cf)

    if (field.through) {
      const { value, valueCache } = field
      const added = value
        .filter(v => !valueCache.find(vc => vc.geozone_id === v))
        .map(v => ({ vehicle_id: id, [field.foreign_key]: v }))
      const removed = valueCache
        .filter(vc => !value.includes(vc.geozone_id))
        .map(vc => ({ id: vc.id, disabled: true }))

      item[field.through] = [...added, ...removed]
    } else {
      const key = field.foreign_key || field.source_name

      item[key] = field.value
    }
  })

  return item
}
