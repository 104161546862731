<template>
  <div
    v-loading="loading"
    class="journal"
  >
    <journal-header
      v-if="!!related.type"
      title="Журнал событий"
      :active-type="activeType"
      :map-visible="isMapVisible"
      :object-ids="objectIds"
      @toggle="toggleMap"
      @change-type="typeChangeHandler"
    />
    <div class="journal__filters">
      <r-search-input
        v-if="!typesWithoutSearchInput.includes(activeType)"
        placeholder="Поиск по ГРЗ"
        :filter-text="filterText"
        @change="filterText = $event"
      />
      <r-filter-set
        v-if="currentFilters"
        :filters="currentFilters"
        @reset-all="loadEvents"
        @load="updateFilters"
      />
    </div>
    <div class="journal__content">
      <journal-map
        v-if="isMapVisible && halfHeigth"
        :height="halfHeigth"
      />
      <r-table-list
        v-if="events.length"
        ref="table"
        :include-columns="columns"
        :fields-names="tableFields"
        :data="tableData"
        bordered
        num-col
        selectable
        actions
      >
        <template v-slot:actions="{ row }">
          <div class="journal-table__action-cell">
            <div
              v-if="hasGeomButton(row)"
              class="journal-table__action-row"
            >
              <button
                class="journal-table__action-button"
                @click.stop="showOnMap(row)"
              >
                <r-icon
                  name="focus-zone"
                  :size="20"
                />
                <r-text> На карте </r-text>
              </button>
            </div>
            <div
              v-if="hasImageButton(row)"
              class="journal-table__action-row"
            >
              <button
                class="journal-table__action-button"
                @click.stop="showCameraImage(row)"
              >
                <r-icon
                  name="image"
                  :size="20"
                />
                <r-text> Изображение </r-text>
              </button>
            </div>
            <div
              v-if="hasCreateVehicle(row)"
              class="journal-table__action-row"
            >
              <button
                class="journal-table__action-button"
                @click.stop="openCreateVehicle(row)"
              >
                <r-icon
                  name="add-plus"
                  :size="20"
                />
                <r-text> Создать ТС </r-text>
              </button>
            </div>
          </div>
        </template>
      </r-table-list>
      <div
        v-else
        class="journal__nodata"
      >
        <r-button
          v-if="!firstLoad"
          type="primary"
          @click="loadEvents"
        >
          Загрузить данные
        </r-button>
        <r-text
          v-else
          type="caption"
        >
          Список событий пуст
        </r-text>
      </div>
      <el-pagination
        v-if="hasPagination"
        class="r-pagination"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="totalObjects"
        @current-change="handleCurrentChange"
      />
    </div>
    <create-vehicle
      v-if="showCreate && createVehicleRegNumber"
      :is-visible="showCreate"
      :reg-number="createVehicleRegNumber"
      @close="showCreate = false"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import constants from '@/constants/url'
import JournalHeader from './components/header'
import JournalMap from './components/map/map'
import CreateVehicle from './components/create-vehicle'
import { filters } from './config/filters'
import { related } from './config/related'
import { requestConfigs, requestUrls } from './config/types'
import { fields as tableFields, columns } from './config/table'
import debounce from 'lodash.debounce'
import { getGroupId, filtersEncoder, ritmDate } from '@/utils'

export default {
  components: {
    JournalHeader,
    JournalMap,
    CreateVehicle
  },
  data() {
    return {
      firstLoad: false,
      url: constants.URL,
      loading: false,
      isMapVisible: false,
      events: [],
      related: cloneDeep(related),
      filters: cloneDeep(filters),
      filterText: '',
      typesWithoutSearchInput: ['system_event', 'videoanalytics'],
      currentPage: 1,
      pageSize: 50,
      totalObjects: null,
      showCreate: false,
      createVehicleRegNumber: null,
      tableFields
    }
  },
  computed: {
    hasPagination() {
      return this.totalObjects > this.pageSize
    },
    columns() {
      return columns[this.activeType]
    },
    tableData() {
      const pageSize = this.pageSize * (this.currentPage - 1)
      return this.events?.map((e, i) => {
        return {
          ...e,
          event_time: e.time ? ritmDate.toFormat(e.time, 'DD.MM.YYYY • HH:mm') : 'Не определено',
          no: i + 1 + pageSize
        }
      })
    },
    objectIds() {
      return this.tableData?.map(e => e.id) || []
    },
    currentFilters() {
      return this.filters[this.activeType]
    },
    halfHeigth() {
      return Math.round(document.documentElement.clientHeight / 2 - 80)
    },
    activeType: {
      get() {
        return this.$store.state.journal.activeType
      },
      set(value) {
        this.$store.commit('SET_JOURNAL_FIELD', ['activeType', value])
      }
    }
  },
  watch: {
    filterText() {
      this.updateEvents()
    }
  },
  async mounted() {
    await this.loadRelated()
  },
  methods: {
    updateEvents: debounce(function() {
      this.loadEvents()
    }, 256),
    handleCurrentChange(val) {
      this.currentPage = val
      this.loadEvents(true)
    },
    setInitialFilter() {
      const yesterday = this.$rDate
        .calc(-1, 'day', this.$ritmDate.date())
        .zeroing()
        .format('iso')
      const filters = this.filters[this.activeType]
      const intervalFilter = filters.find(
        ({ type }) => type === 'interval-filter'
      )

      intervalFilter.active = true
      intervalFilter.prop.interval.active = true
      intervalFilter.prop.interval.from = yesterday
    },
    typeChangeHandler(type) {
      this.activeType = type
      this.filterText = ''
      this.currentPage = 1

      this.loadEvents()
    },
    updateFilters(filter) {
      const filters = this.filters[this.activeType]

      const target = filters.find(f => f.id === filter.id)
      const item = { ...target, ...filter }

      filters[filters.indexOf(target)] = item

      this.loadEvents()
    },
    filter_config(f, r) {
      let filter = f
      if (f.source === r) {
        filter = {
          ...f,
          prop: this.related[r].data.map(d => ({
            ...d,
            value: false
          }))
        }
      }
      return {
        ...filter
      }
    },
    async loadRelated() {
      for (const r in this.related) {
        // if (!this.related[r].id) return
        try {
          let selectData
          if (this.related[r].select_store) {
            selectData = this.$selectClientStore.getData(this.related[r].select_store).data
          } else if (this.related[r].id) {
            const config = JSON.stringify(this.related[r].config)
            const url = `objectInfo/${this.related[r].id}?config=${config}`

            const { data } = await this.$store.dispatch('GET_REQUEST', {
              url
            })
            selectData = data
          }

          this.related[r].data = Object.values(selectData)

          this.filters.car_event = this.filters.car_event.map(f => this.filter_config(f, r))
          this.filters.gazprom = this.filters.gazprom.map(f => this.filter_config(f, r))
          this.filters.videophoto = this.filters.videophoto.map(f => this.filter_config(f, r))
          this.filters.videoanalytics = this.filters.videoanalytics.map(f => this.filter_config(f, r))
          this.filters.nipigaz = this.filters.nipigaz.map(f => this.filter_config(f, r))
          this.filters.nipigaz_pot = this.filters.nipigaz_pot.map(f => this.filter_config(f, r))
        } catch (e) {
          console.warn(e)
        }
      }
    },
    getRequestUrl(aggr = false) {
      const config = this.getConfig(aggr)
      const uri = aggr ? requestUrls[this.activeType].aggr : requestUrls[this.activeType].url
      const options = uri?.includes('?') ? `&config=${config}` : `?config=${config}`

      return `${uri}${options}&page=${this.currentPage}&limit=${this.pageSize}`
    },
    getExportUrl() {
      const config = this.getConfig(true)
      const urlOptions = requestUrls[this.activeType]
      const { url, column_order, with_numeration, column_labels } = urlOptions

      const options = url?.includes('?') ? `&config=${config}` : `?config=${config}`

      let fullUrl = `${url}${options}`

      if (column_order) {
        fullUrl = fullUrl + `&column_order=${JSON.stringify(column_order)}`
      }

      if (with_numeration) {
        fullUrl = fullUrl + '&with_numeration=true'
      }

      if (column_labels) {
        fullUrl = fullUrl + `&column_labels=${JSON.stringify(column_labels)}`
      }
      return fullUrl
    },
    async loadEvents(pagination) {
      this.loading = true
      this.firstLoad = true
      this.events = []

      try {
        if (!pagination) {
          this.currentPage = 1
        }
        const url = this.getRequestUrl()
        // const aggrUrl = this.getRequestUrl(true)
        const exportUrl = this.getExportUrl()

        this.$store.commit('SET_JOURNAL_FIELD', ['exportUrl', exportUrl])

        // const { data: aggregation } = await this.$store.dispatch('GET_REQUEST', { url: aggrUrl })

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        this.totalObjects = data.pagy.count || 0

        this.events = data.data
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    },
    getConfig(aggr = false) {
      const init = requestConfigs[this.activeType]
      const config = {
        ...requestConfigs[this.activeType],
        ...filtersEncoder(this.filters[this.activeType])
      }

      if (init.where) {
        init.where.forEach(iw => {
          if (config.where.find(cw => cw.field === iw.field)) return
          config.where.push(iw)
        })
      }

      const query = this.filterText?.trim()?.toLowerCase()

      if (query) {
        const field = this.activeType === 'car_event'
          ? 'vehicle.reg_number'
          : 'reg_number'

        config.where.push({
          field,
          value: query,
          op: 'like'
        })
      }
      return JSON.stringify(config)
    },
    toggleMap() {
      this.isMapVisible = !this.isMapVisible
    },
    hasGeomButton(row) {
      return !!row.event_geom || !!row.geom
    },
    hasImageButton(row) {
      const { event_type } = row

      switch (this.activeType) {
        case 'videophoto':
          return event_type !== 'Проезд ТС ФВФ'
        case 'videoanalytics':
          return event_type !== 'Проезд ТС по видеоаналитике'
      }

      return false
    },
    hasCreateVehicle(row) {
      const query = ['gazprom', 'videophoto', 'videoanalytics', 'nipigaz_pot', 'nipigaz']

      if (!query.includes(this.activeType)) return false

      return !row.vehicle_id
    },
    openCreateVehicle(row) {
      if (!row.reg_number) return

      this.createVehicleRegNumber = row.reg_number
      this.showCreate = true
    },
    showOnMap(row) {
      const { event_geom, geom } = row

      if (!event_geom && !geom) return

      this.$refs.table.highlightRow(row)
      this.isMapVisible = true

      this.$store.commit('SET_JOURNAL_FIELD', ['flyToGeom', event_geom || geom])
    },
    showCameraImage(row) {
      const { id } = row
      const url = `${this.url}/camera_traffic?id=${id}&format=images&groupid=${getGroupId()}`

      if (url) {
        window.open(url)
      }
    }
  }
}
</script>

<style lang="scss">
.journal {
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  width: 100%;
  align-content: start;
  height: 100%;
  grid-template-rows: auto auto 1fr;

  &__filters {
    display: flex;
    align-items: center;
    background-color: var(--bg_panel_primary);
    border-bottom: 1px solid var(--dividers_low_contrast);

    .r-search-input {
      width: 220px;
      padding-left: 1rem;
    }
  }

  &__content {
    display: grid;
    overflow: hidden;
    height: 100%;
    align-items: start;
    justify-content: stretch;

    .r-title {
      justify-content: center;
      width: 100%;
      padding: 1rem;
      text-align: center;
    }
  }

  &-table {
    &__action {
      &-cell {
        display: flex;
        flex-direction: column;
      }

      &-button {
        border-radius: var(--border-radius);
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        cursor: pointer;

        .r-icon {
          margin-right: 8px;
        }

        &:hover {
          background-color: var(--accent_hover);
        }
      }
    }
  }

  &__nodata {
    display: flex;
    justify-content: center;
    padding: 2rem;
    align-self: baseline;
  }
}
</style>
