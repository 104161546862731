<template>
  <div class="mail-notification">
    <r-button
      type="primary"
      :disabled="!objectIds?.length"
      @click="visible = true"
    >
      Отправить уведомления
    </r-button>
    <modal-comp
      v-if="visible"
      :object-ids="objectIds"
      :module="module"
      @close="close"
    />
  </div>
</template>

<script>
export default {
  components: {
    modalComp: () => import('./mail-modal.vue')
  },
  props: {
    module: {
      type: String,
      required: true
    },
    objectIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    close() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss">
.mail-notification {
  display: grid;
}
</style>
